/* @import "./styles/layout/_navbar.scss"; */

/* Home Page Style */

/* Font Family */

@font-face {
  font-family: "Gilroy-Medium";
  src: url("./assets/fonts/Gilroy-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy-Regular";
  src: url("./assets/fonts/Gilroy-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy-Semibold";
  src: url("./assets/fonts/Gilroy-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy-Bold";
  src: url("./assets/fonts/Gilroy-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Cirka-Bold";
  src: url("./assets/fonts/Cirka-Bold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Cirka-Regular";
  src: url("./assets/fonts/Cirka-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

/* Text Size */

span,
p,
BootstrapDialog,
DialogTitle,
DialogContent,
.css-140puxv-MuiTypography-root-MuiDialogTitle-root {
  font-family: "Gilroy-Regular", sans-serif;
}

/* Webkit Custom */

::-webkit-scrollbar {
  width: 13px;
}

::-webkit-scrollbar-track {
  border-radius: 3px;
  background-color: #000;
}

::-webkit-scrollbar-thumb {
  background: #000;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background: #5a5959;
}

/* Common */

* {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
  /* font-family: "Gilroy-Regular", sans-serif; */
  /* background-color: #000; */
}

html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-weight: 400;
  box-sizing: border-box;
  height: 100%;
  background-color: #000 !important;
  background: #000;
  font-family: "Gilroy-Regular", sans-serif;
}

/* Font Size */

.ct-cirka-size-30 {
  font-size: 30px;
  font-family: "Cirka-Bold", sans-serif;
  font-weight: 700;
}
.ct-cirka-size-60 {
  font-size: 60px;
  font-family: "Cirka-Bold";
  font-weight: 700;
}
.ct-cirka-size-40 {
  font-size: 40px;
  font-family: "Cirka-Bold", sans-serif;
  font-weight: 700;
}
.ct-cirka-size-48 {
  font-size: 48px;
  font-family: "Cirka-Bold", sans-serif;
  font-weight: 700;
}
.ct-text-size-80 {
  font-size: 80px;
  font-family: "Gilroy-Bold", sans-serif;
  font-weight: 600;
}
.ct-text-size-70 {
  font-size: 80px;
  font-family: "Gilroy-Bold", sans-serif;
  font-weight: 600;
}
.ct-text-size-60-Bold {
  font-size: 60px !important;
  font-family: "Gilroy-Bold", sans-serif !important;
  font-weight: 600;
}
.ct-text-size-25 {
  font-size: 25px;
  font-family: "Gilroy-Bold", sans-serif;
  font-weight: 600;
}
.ct-text-size-50 {
  font-size: 50px;
  font-family: "Gilroy-Bold", sans-serif;
  font-weight: 600;
}
.ct-text-size-24 {
  font-size: 24px;
  font-family: "Gilroy-SemiBold", sans-serif;
  font-weight: 500;
}
.ct-text-size-24-bold {
  font-size: 24px;
  font-family: "Gilroy-bold", sans-serif;
  font-weight: 500;
}
.ct-text-size-med-24 {
  font-size: 24px;
  font-family: "Gilroy-medium", sans-serif;
  font-weight: 400;
}
.ct-text-size-40 {
  font-size: 40px;
  font-family: "Gilroy-Bold", sans-serif;
  font-weight: 400;
  color: black;
}
.ct-text-size-30-bold {
  font-size: 30px !important;
  font-family: "Gilroy-Bold", sans-serif !important;
  font-weight: 400;
  color: black;
}
.ct-text-size-20-bold {
  font-size: 20px !important;
  font-family: "Gilroy-Bold", sans-serif !important;
  font-weight: 400;
  color: black;
}
.ct-text-size-40-gray {
  font-size: 40px;
  font-family: "Gilroy-Bold", sans-serif;
  font-weight: 400;
  color: gray;
}
.ct-text-size-16-gray {
  font-size: 16px;
  font-family: "Gilroy-SemiBold", sans-serif;
  font-weight: 400;
  color: gray;
}
.ct-text-size-16-med {
  font-size: 16px;
  font-family: "Gilroy-medium", sans-serif;
  font-weight: 400;
}
.ct-text-size-16-light {
  font-size: 16px;
  font-family: "Gilroy-Light", sans-serif;
  font-weight: 400;
}
.ct-text-size-12-med {
  font-size: 12px;
  font-family: "Gilroy-medium", sans-serif;
  font-weight: 400;
}
.ct-text-size-12 {
  font-size: 12px;
  font-family: "Gilroy-Semibold", sans-serif;
  font-weight: 400;
}
.ct-text-size-12-reg {
  font-size: 12px;
  font-family: "Gilroy-Regular", sans-serif;
  font-weight: 400;
}
.ct-text-size-20 {
  font-size: 20px;
  font-family: "Gilroy-Bold", sans-serif;
  font-weight: 400;
}
.ct-text-size-22 {
  font-size: 22px;
  font-family: "Gilroy-Bold", sans-serif;
  font-weight: 400;
}
.ct-text-size-26 {
  font-size: 26px;
  font-family: "Gilroy-Bold", sans-serif;
  font-weight: 400;
}
.ct-text-size-30 {
  font-size: 30px !important;
  font-family: "Gilroy-Bold", sans-serif;
  font-weight: 400;
}
.ct-text-size-20 {
  font-size: 20px;
  font-family: "Gilroy-SemiBold";
  font-weight: 500;
}
.ct-text-size-18 {
  font-size: 18px;
  font-family: "Gilroy-SemiBold";
  font-weight: 400;
  color: gray;
}
.ct-text-size-20-black {
  font-size: 20px;
  font-family: "Gilroy-SemiBold";
  font-weight: 400;
  color: rgb(0, 0, 0);
}
.ct-text-size-24 {
  font-size: 24px;
  font-family: "Gilroy-SemiBold";
  font-weight: 400;
  color: rgb(0, 0, 0);
}
.ct-text-size-16 {
  font-size: 16px;
  font-family: "Gilroy-SemiBold";
  font-weight: 400;
}

.ct-text-size-14 {
  font-size: 14px;
  font-family: "Gilroy-medium";
  font-weight: 400;
  letter-spacing: 1px;
}
.ct-text-size-14-bold {
  font-size: 14px;
  font-family: "Gilroy-Bold";
  font-weight: 400;
}
.ct-text-size-11 {
  font-size: 11px;
  font-family: "Gilroy-medium", sans-serif;
  font-weight: 400;
}
.ct-text-size-14-gray {
  font-size: 14px;
  font-family: "Gilroy-medium", sans-serif;
  font-weight: 400;
  letter-spacing: 1px;
  color: gray;
}

.ct-cirka-size-25 {
  font-size: 25px !important;
  font-family: "Cirka-Bold", sans-serif;
  font-weight: 600;
}
.ct-cirka-size-22 {
  font-size: 22px !important;
  font-family: "Cirka-Bold", sans-serif;
  font-weight: 600;
}
.ct-text-26 {
  font-size: 26px;
}
.ct-text-30 {
  font-size: 50px !important;
}
.ct-text-20 {
  font-size: 20px;
}
.ct-text-24 {
  font-size: 24px;
}
.ct-text-18 {
  font-size: 18px;
}
.ct-text-weight-600 {
  font-weight: 600 !important;
}
.ct-text-weight-500 {
  font-weight: 500;
}
.ct-text-16 {
  font-size: 16px;
}
.ct-cirka-size-50 {
  font-size: 50px;
  font-family: "Cirka-Bold", sans-serif;
  font-weight: 600;
}
.ct-cirka-size-26 {
  font-size: 26px;
  font-family: "Cirka-bold";
  font-weight: 700;
}
.ct-gilroy-semibold {
  font-family: "Gilroy-SemiBold", sans-serif;
}
.ct-text-13 {
  font-size: 13px !important;
}
.ct-text-14 {
  font-size: 14px !important;
}

/* Color */

.ct-bg-gray {
  background: #fff;
}
.ct-text-gray {
  color: rgba(255, 255, 255, 0.7);
}
.ct-text-dark-gray {
  color: #6d6d6d;
}
.ct-text-dark {
  color: #0d0d0d !important;
}
.ct-text-light {
  color: #fff !important;
}
.ct-bg-light {
  background-color: #fff;
}
.ct-bg-dark {
  background-color: #000;
}
.ct-text-green {
  color: #76ffc6;
}
/* Text Align */

.ct-text-left {
  text-align: left;
}
.ct-text-center {
  text-align: center;
}
.ct-text-right {
  text-align: right;
}
.ct-text-warning {
  color: #ff8761;
}

.landing-page-main {
  .landing-dark-bg {
    background: #000;
  }
  .main-div {
    width: 100%;
    max-width: 100vw;
    padding-top: 130px;
  }

  .div-1 {
    max-width: 1000px;
    width: 100%;
  }

  .div-2 {
    max-width: 900px;
    width: 100%;
  }

  .div-3 {
    max-width: 700px;
    width: 100%;
  }

  .div-4 {
    max-width: 500px;
    width: 100%;
  }

  .react-reveal {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul,
  li {
    margin: 0;
    padding: 0;
  }

  .App {
    text-align: center;
    background-color: #000;
  }

  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }

  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }

  .App-header {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    background-color: #000;
  }

  .App-link {
    color: #61dafb;
  }
  .ct-top-50 {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .ct-center-fixed {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ct-center-fixed-mbl {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .head-div-second-text {
    font-size: 50px !important;
    font-family: "Gilroy-Bold", sans-serif;
  }

  .ct-banner-title {
    font-size: 60px !important;
    font-family: "Gilroy-Bold", sans-serif;
  }

  p,
  span {
    font-family: "Gilroy-Regular", sans-serif;
  }

  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .ct-d-flex-justify-center {
    display: flex !important;
    justify-content: space-between;
  }

  .ct-title {
    font-size: 30px !important;
    font-family: "Gilroy-Bold", sans-serif;
    font-weight: 700;
  }

  .ct-title-workshop {
    font-size: 60px !important;
    font-family: "Gilroy-Bold", sans-serif;
    font-weight: 700;
  }

  .ct-para-text {
    font-size: 15px;
    color: rgba(255, 255, 255, 0.7);
  }

  .image-slider {
    width: 100%;
    /* overflow-x: hidden; */
    overflow-x: auto;
    white-space: nowrap;
    display: flex;
  }

  .image-slider img {
    flex: 0 0 auto;
    height: 300px;
    margin-right: 10px;
  }

  .flashback-image-slider {
    width: 100%;
    overflow-x: hidden;
    white-space: nowrap;
    display: flex;
  }

  .flashback-image-slider img {
    flex: 0 0 auto;
    height: 200px;
    margin-right: 10px;
  }
  .jury-image-slider {
    width: 100%;
    overflow-x: hidden;
    white-space: nowrap;
    display: flex;
  }
  .jury-image-slider img {
    flex: 0 0 auto;
    height: 500px !important;
    margin-right: 10px;
  }
  .panel-title {
    font-size: 18px !important;
    font-family: "Gilroy-Semibold", sans-serif !important;
    color: "#FFF";
  }
  .panel-sub-title {
    font-size: 13px !important;
    font-family: "Gilroy-Regular", sans-serif !important;
    color: rgba(179, 179, 179, 1);
  }

  .panel-sub-title-testimonial {
    font-size: 13px;
    font-family: "Gilroy-Regular", sans-serif;
    color: rgba(179, 179, 179, 1);
    font-weight: 600;
  }

  .ct-top-30 {
    padding-top: 80px !important;
  }

  .categories-one-bg-img {
    background: url("./assets/categories_1.png") transparent;
    background-attachment: scroll;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 600px;
    display: flex;
    justify-content: flex-end;
    .head {
      height: 100%;
      display: flex;
    }
  }

  .categories-two-bg-img {
    background: url("./assets/categories_2.png") transparent;
    background-attachment: scroll;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 600px;
    display: flex;
    justify-content: flex-end;
    .head {
      height: 100%;
      display: flex;
    }
  }

  .categories-three-bg-img {
    background: url("./assets/categories_3.png") transparent;
    background-attachment: scroll;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 600px;
    display: flex;
    justify-content: flex-end;
    .head {
      height: 100%;
      display: flex;
    }
  }

  .category-content {
    padding-top: 130px;
    padding-right: 30px;
  }

  .category-content-three {
    padding-top: 50px;
    padding-right: 30px;
  }

  .categories-title {
    font-size: 45px;
    font-family: "Gilroy-Bold", sans-serif;
    font-weight: 700;
    color: "#FFF";
  }

  .ct-category-sub-Amount {
    font-size: 25px;
    font-family: "Gilroy-SemiBold", sans-serif;
    font-weight: bold;
    color: rgba(217, 217, 217, 1);
  }

  .ct-padding-left-30 {
    padding-left: 50px;
  }

  .ct-background-transparent {
    background: transparent;
  }

  .meet-display-flex {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (min-width: 0px) and (max-width: 450px) {
    .ws-card-first {
      width: 100% !important;
    }
    .ws-card-second {
      width: 100% !important;
    }
    .ct-d-flex-justify-center {
      display: unset !important;
    }
    .ct-ws-badge-gray {
      padding: 7px 14px;
      display: flex;
      flex-direction: column;
    }
    .ct-ws-d-flex-center {
      display: unset !important;
      justify-content: center;
    }
    .ct-web-border-radius-15 {
      border-radius: 15px;
    }
    .video-thumbnail {
      width: 94% !important;
      position: relative;
    }
    .cardHeight {
      height: 340px;
    }

    .custom-arrow-prev {
      bottom: -50px;
      left: 40% !important;
      right: 0px;
      transform: translate(-50%, -50%);
    }
    .video-card {
      margin: 0px !important;
    }
    .custom-arrow-next {
      bottom: -50px;
      left: 60% !important;
      right: 0px;
      transform: translate(-50%, -50%);
    }
    .web-mb-30 {
      margin-bottom: 0px !important;
    }
    .sponsors-second {
      position: relative;
      top: -3px !important;
    }
    .sponsors-third {
      position: relative;
      top: 0px !important;
    }
    .sponsors-five {
      position: relative;
      top: 0px !important;
    }
    .sponsors-six {
      position: relative;
      top: 0px !important;
    }
    .sponsors-seven {
      position: relative;
      top: 0px !important;
    }
    .sponsors-eight {
      position: relative;
      top: 0px !important;
    }
    .sponsors-nine {
      position: relative;
      top: 0px !important;
    }
    .sponsors-ten {
      position: relative;
      top: 0px !important;
    }
    .ct-top-30 {
      padding-top: 30px !important;
    }
    .jury-image-slider {
      width: 100%;
      overflow-x: auto;
      white-space: nowrap;
      display: flex;
    }
    .jury-image-slider img {
      flex: 0 0 auto;
      height: 500px !important;
      margin-right: 10px;
    }
    .web-jc-center {
      justify-content: unset !important;
    }
    .mbl-ml-10 {
      position: relative;
      left: 15px;
    }
    .ct-center-fixed-mbl {
      display: unset !important;
      justify-content: center;
      align-items: center;
    }
    .header-nav {
      background-color: rgb(0, 0, 0) !important;
    }
    .ct-home-title-100 {
      font-size: 80px;
      font-weight: 600;
      font-family: "Gilroy-Bold" !important;
    }

    .ct-p-5 {
      padding: 20px !important;
    }
    .categories-title {
      font-size: 30px !important;
    }
    .ct-category-sub-text {
      font-size: 16px !important;
    }
    .categories-one-bg-img,
    .categories-two-bg-img,
    .categories-three-bg-img {
      background-size: cover !important;
    }
    .meet-display-flex {
      display: unset !important;
    }
    .mbl-text-left {
      text-align: center !important;
    }
    .ct-disply-flex {
      display: unset !important;
    }
    .mbl-pt {
      padding-top: 30px;
    }
    .footer-text-align {
      margin-right: 0px !important;
    }
    .mbl-mr-30 {
      margin-right: 0px !important;
    }
    .mbl-text-center {
      text-align: center !important;
    }
    .about-first-section {
      width: 100% !important;
    }
    .about-second-section {
      width: 100% !important;
    }
    .testimonial-slider-container {
      width: 100% !important;
      margin: 0 auto !important;
    }
    .category-content-three {
      padding-right: 10px;
    }
    .main-div {
      padding-top: 60px !important;
    }
    .mbl-mb-30 {
      margin-bottom: 45px !important;
    }
    .web-mr-30 {
      margin-right: 0px !important;
    }
    .ct-top-50 {
      padding-top: 50px !important;
    }
    .categories-one-bg-img,
    .categories-two-bg-img {
      height: 450px !important;
    }
    .ct-padding-left-30 {
      padding-left: 10px !important;
    }
    .categories-three-bg-img {
      height: 550px !important;
    }
    .about-image {
      width: 300px;
    }
    .else-image {
      width: 300px;
    }
    .ct-category-sub-Amount {
      font-size: 20px !important;
    }
    .landing-text-top {
      padding-top: 10px;
    }
    .video-height {
      height: 92% !important;
    }
    .workshop-date-time-card {
      width: 100%;
    }
    .ct-mentor-img-size {
      width: 300px;
    }
    .ct-text-size-60-Bold {
      font-size: 30px !important;
      font-family: "Gilroy-Bold", sans-serif !important;
      font-weight: 600;
    }
    .mentor-display-flex {
      text-align: center !important;
      display: unset !important;
      justify-content: center;
    }
    .faqs-display-flex {
      text-align: unset !important;
      display: unset !important;
      justify-content: center;
    }
    .ct-mbl-mt-10 {
      margin-top: 25px !important;
    }
    .ct-mr-30 {
      margin-right: 0px !important;
    }
    .ct-width-80 {
      width: 100%;
    }
    .ct-width-10 {
      width: unset !important;
    }
    .ct-width-50 {
      width: 100%;
    }
    .ct-width-25 {
      width: unset !important;
    }
    .ws-first-section {
      width: 100% !important;
    }
    .ws-second-section {
      width: 100% !important;
    }
  }

  @media (min-width: 500px) and (max-width: 991px) {
    .categories-one-bg-img,
    .categories-two-bg-img,
    .categories-three-bg-img {
      background-size: cover !important;
    }
    .ct-ws-badge-gray {
      padding: 5px 11px;
    }
    .ct-ws-d-flex-center {
      display: unset !important;
      justify-content: center;
    }
    .ct-web-border-radius-15 {
      border-radius: 15px;
    }
    .categories-title {
      font-size: 35px !important;
    }
    .ws-first-section {
      width: 100% !important;
    }
    .ws-second-section {
      width: 100% !important;
    }
    .ct-width-70 {
      width: 100%;
    }
    .ct-width-30 {
      width: 40%;
    }
    .ct-display-flex-bottom {
      flex-direction: column;
    }
    .ct-text-size-80 {
      font-size: 50px !important;
    }
    .ct-text-size-50 {
      font-size: 40px !important;
    }
    
    .image-card-ws {
      width: 270px;
      display: none;
    }
    .image-card-ws-card {
      width: 260px;
    }
    .workshop-date-time-card {
      width: 25% !important;
      width: 31% !important;
    }
    .ct-text-size-24 {
      font-size: 18px;
    }
    .ct-text-size-60-Bold {
      font-size: 30px !important;
      font-family: "Gilroy-Bold", sans-serif !important;
      font-weight: 600;
    }
  }

  .apply-now-fill-small {
    background: linear-gradient(90deg, #ee36ff 0%, #ff8762 100%);
    padding: 18px 26px;
    border-radius: 10px;
    text-decoration: none;
    color: #fff !important;
    font-size: 16px !important;
    font-family: "Gilroy-Medium", sans-serif;
    font-weight: 600;
  }
  .apply-now-fill {
    background: linear-gradient(90deg, #ee36ff 0%, #ff8762 100%);
    padding: 15px 40px;
    border-radius: 10px;
    text-decoration: none;
    color: #fff !important;
    font-size: 30px;
    font-family: "Gilroy-Medium", sans-serif;
    font-weight: 600;
  }
  .apply-now-fill:hover&&:focus {
    background: -webkit-linear-gradient(90deg, #ee35ff 0%, #ff8761 100%);
    background: linear-gradient(90deg, #ee35ff 0%, #ff8761 100%);
    -webkit-text-fill-color: transparent;
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    background-clip: text;
    transition: color 0.3s ease-in-out;
    transition: color 0.3s ease-in-out;
  }

  .home-text-center {
    text-align: center;
  }

  .ct-title-social {
    font-size: 30px !important;
    font-weight: 500;
    color: "#FFF";
    font-family: "Gilroy-Bold", sans-serif;
  }

  .footer-float-right {
    float: right;
  }
  .ct-flex-row {
    flex-direction: row;
    width: 100%;
  }
  .ct-disply-flex {
    display: flex;
  }

  .footer-text-align {
    text-align: left;
    margin-right: 30px;
  }
  a {
    .landing-page-main {
      & .header-nav.sticky {
        & .brand-name,
        & .nav-link {
          &:hover {
            color: rgb(237, 48, 255) !important;
          }
        }
      }
    }
  }

  .mbl-text-left {
    text-align: left;
  }
  .copy-right-text {
    font-size: 16px;
    font-family: "Gilroy-Regular", sans-serif;
    color: #ffff;
  }

  .mbl-mt {
    margin-top: 30px;
  }
  .mbl-mr-30 {
    margin-right: 30px;
  }
  .mbl-text-center {
    text-align: left;
  }
  .apply-now-span {
    padding: 9px 19px;
    color: rgb(255, 255, 255);
    position: relative;
    background: black;
    border-radius: 100px;
    overflow: hidden;
    font-size: 16px !important;
    font-family: "Gilroy-Regular", sans-serif;
    font-weight: 500;
  }
  .apply-now-span:hover {
    color: rgb(255, 255, 255) !important;
  }
  .apply-now-border {
    border-radius: 85px;
    overflow: hidden;
    width: 120px;
    height: 46px;
    background: linear-gradient(
      90deg,
      rgb(237, 48, 255) 0%,
      rgb(255, 135, 97) 100%
    );
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .apply-now-border-logout {
    border-radius: 85px;
    overflow: hidden;
    width: 94px;
    height: 46px;
    background: linear-gradient(
      90deg,
      rgb(237, 48, 255) 0%,
      rgb(255, 135, 97) 100%
    );
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .about-first-section {
    width: 50%;
  }
  .about-second-section {
    width: 50%;
  }

  .ct-about-para-text {
    font-size: 20px;
    font-family: "Gilroy-Regular", sans-serif !important;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.7);
  }

  .testimonial-avatar {
    width: 70px;
    height: 70px;
    border-radius: 100px;
    object-fit: cover;
  }

  .testimonial-avatar-flex-center {
    display: flex;
    justify-content: center;
  }
  .testimonial-slider-container {
    width: 70%;
  }
  .testimonial-avatar-flex {
    display: flex;
  }

  .testmonial-slider-border {
    border: 1px solid #fff;
    margin: 5px;
    padding: 35px;
    border-radius: 10px;
  }

  .header-nav {
    background: transparent;
    transition: all 0.5s ease;
    color: #ffff;
    .brand-name {
      color: whitesmoke;
      font-size: xx-large;
      font-family: "Gilroy", sans-serif;
      transition: all 0.5s ease;
      &:hover {
        color: #ee35ff !important;
        transition: color 0.3s ease-in-out;
      }
      &:focus {
        color: rgb(255, 255, 255) !important;
      }
    }
    .navbar-toggler {
      border-color: #ffff;
      transition: all 0.5s ease;
      &-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255,1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
      }
    }
    .nav-link {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff !important;
      font-size: 17px;
      font-family: "Gilroy-Medium", sans-serif;
      transition: all 0.5s ease;
      :hover {
        color: #ee35ff !important;
      }
      /* &:hover,
      &:focus {
        background: -webkit-linear-gradient(90deg, #ee35ff 0%, #ff8761 100%);
        background: linear-gradient(90deg, #ee35ff 0%, #ff8761 100%);
        -webkit-text-fill-color: transparent;
        color: transparent;
        -webkit-background-clip: text;
        background-clip: text;
        transition: color 0.3s ease-in-out;
        transition: color 0.3s ease-in-out;
      } */
      .button {
        color: #ee35ff;
        border-color: #ee35ff;
        /* &:hover, */
        &:focus,
        &:active {
          background: -webkit-linear-gradient(90deg, #ee35ff 0%, #ff8761 100%);
          background: linear-gradient(90deg, #ee35ff 0%, #ff8761 100%);
          -webkit-text-fill-color: transparent;
          color: transparent;
          -webkit-background-clip: text;
          background-clip: text;
          transition: color 0.3s ease-in-out;
          transition: color 0.3s ease-in-out;
        }
      }
    }
  }

  .header-nav.sticky {
    background-color: #000 !important;
    transition: all 0.5s ease;
    padding-top: 2px;
    padding-bottom: 2px;
    .brand-name,
    .nav-link {
      color: #e9e9e9 !important;
      transition: all 0.5s ease;
      &:hover {
        color: #ff1cfb !important;
        transition: color 0.3s ease;
      }
    }
    .nav-link {
      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
        transition: all 0.5s ease;
      }
      &:nth-last-child(1):hover {
        background-color: #000 !important;
        transition: all 0.5s ease;
      }
    }
  }
  .navbar-dark .navbar-nav .nav-link {
    color: rgb(255, 255, 255) !important;
  }

  .about-image {
    width: 350px;
  }
  .else-image {
    width: 86%;
  }

  .event-header {
    display: flex !important;
    justify-content: space-between !important;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}

/* ************************************************* */

/* Event & Normal Css */

/* ************************************************* */

/* Input Custom */

input,
select,
textarea,
ul,
li {
  font-family: "Gilroy-Regular", sans-serif !important;
}
.ct-input-bg-custom {
  input,
  textarea,
  select {
    background-color: #000 !important;
    color: #fff !important;
    font-family: "Gilroy-Regular", sans-serif !important;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ct-check-box-row {
  .css-dmmspl-MuiFormGroup-root {
    flex-direction: row !important;
  }
  .css-j204z7-MuiFormControlLabel-root {
    margin-right: 0px !important;
  }
  .css-1oakzvg-MuiButtonBase-root-MuiCheckbox-root,
  .css-9veiix-MuiButtonBase-root-MuiCheckbox-root {
    padding: 0px !important;
  }
  .css-9veiix-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
  .css-9veiix-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
    padding: 0px !important;
  }
}

.check-box-mr-0 {
  .css-j204z7-MuiFormControlLabel-root {
    margin-right: 0px !important;
  }
}
.customOtpContainer {
  background-color: #fff !important;
}

.dialog-custom-input {
  width: 400px;
  display: flex;
  justify-content: center;
  OtpInput {
    background-color: #fff !important;
    font-size: 30px;
  }
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.ct-input-box {
  background-color: #fff;
  border-radius: 10px !important;
  padding: 12px;
  font-size: 16px;
  width: 450px;
  background-color: #000000;
  border: 1px solid #37393a;
  color: #ffffff;
}
.ct-textarea-box {
  border-radius: 10px;
  background-color: #000;
  border: 1px solid #c5c8ca;
  padding: 12px;
  font-size: 16px;
  width: 450px;
  height: 100px;
  color: #fff;
}
.ct-input-box-outline {
  background-color: #000;
  border-radius: 10px;
  border: 1px solid #c5c8ca;
  padding: 12px;
  font-size: 16px;
  color: #fff;
}

.input-dob-badge {
  background: #565656;
  padding: 8px 12px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 5px;
}
.phone-no-input {
  background-color: #000000;
  border: 1px solid #37393a;
  color: #b3b3b3;
  border-radius: 10px;
  padding: 12px;
  font-size: 16px;
  width: 60%;
}
.ct-input-card {
  border-radius: 10px;
  padding: 12px;
  font-size: 16px;
  width: 100%;
  font-weight: 600;
  background-color: #000000;
  border: 1px solid #37393a;
  color: #fbfbfb;
}

/* // Color */

.gradient-text {
  background-image: linear-gradient(90deg, #ee36ff 80%, #ff8762 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-size: 15px;
  font-weight: 600;
}
.ct-coupon-gradient-border {
  border-width: 1px;
  border-style: solid;
  border-radius: 10px;
  border-image: linear-gradient(90deg, #ee36ff 0%, #ff8762 100%) 1;
}

/* Margin */

.ct-ml-20 {
  margin-left: 35px;
}
.lg-px-5 {
  padding-left: 3rem;
  padding-right: 3rem;
}
.lg-mx-5 {
  margin-left: 3rem;
  margin-right: 3rem;
}

/* Custom Width */

.event-first-section {
  width: 60%;
}
.event-second-section {
  width: 40%;
}
.ct-state-width {
  width: 200px;
}
.w-450px {
  width: 450px;
}
.w-350px {
  width: 350px;
}
.w-500px {
  width: 500px;
}
.w-600px {
  width: 600px;
}
.w-50 {
  width: 50%;
}
.auto-progress-bar-width {
  width: 50%;
}
.auto-progress-bar-width-70 {
  width: 70%;
}
.auto-complete-width {
  width: 450px;
}
.ct-full-width {
  width: 100%;
}
.ct-width-400 {
  width: 400px;
}
.ct-bottom-width-50 {
  width: 50%;
}
.qr-code-position {
  position: relative;
  top: 40px;
}
.ct-bottom-width-40 {
  /* width: 40%; */
  padding-left: 30px;
}
/* .ct-bottom-width-10 {
  width: 10%;
} */

/* Background badge */

.media-upload-bg {
  background: #fff;
  padding: 15px 30px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 10px;
  color: #000;
  font-weight: 600;
}
.media-upload-later-bg {
  background: #000;
  padding: 15px 30px;
  border: 1px solid #fff;
  font-size: 16px;
  font-weight: 500;
  border-radius: 10px;
  color: #fff;
  font-weight: 600;
  margin-left: 30px;
}
.media-stop-bg {
  background: #000;
  border: 1px solid #fff;
  padding: 15px 25px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 10px;
  color: #fff;
  font-weight: 600;
}
.plus-minus-bg {
  background: #484848;
  padding: 4px 10px;
  border-radius: 8px;
  font-size: 25px;
  font-weight: 600;
  cursor: pointer;
}
.check-icon-green {
  position: absolute;
  top: 50%;
  right: 10;
  transform: translateY(-50%);
  /* color: green !important; */
}

/* Avatar */

.nomine-img {
  width: 50px;
  height: 50px;
}

/* Border */

.ct-border-gray {
  border: 1px solid #c5c8ca;
  border-radius: 10px;
  color: #fff;
}
.gradient-border-1 {
  border-width: 1px;
  border-style: solid;
  border-radius: 10px;
  border-image: linear-gradient(90deg, #ee36ff 0%, #ff8762 100%) 1;
}

.ct-border-top-dashed {
  border-bottom-style: dashed;
  border-bottom-width: 1;
  border-bottom-color: #ffffffb3;
}

/* Margin & Padding */

.nominee-left-5 {
  margin-left: 3rem;
  padding-left: 10px;
}
.award-right-padding {
  padding-left: 15px;
  padding-right: 30px;
  width: 100%;
}
.ct-p-12 {
  padding: 12px;
}

/* Flex */
.ct-display-flex {
  display: flex;
}
.ct-justify-center {
  justify-content: center;
}
.ct-space-between {
  justify-content: space-between;
}
.ct-d-flex-justify-center-web {
  display: flex !important;
  justify-content: space-between;
}
.web-display-flex {
  display: flex;
}
.ct-display-inline-block {
  display: inline-block;
}
.ct-display-flex-bottom {
  display: flex;
}

.ct-cirka-size-30 {
  font-size: 30px;
  font-family: "Cirka-Bold", sans-serif;
  font-weight: 700;
}
.ct-cirka-size-60 {
  font-size: 60px;
  font-family: "Cirka-Bold";
  font-weight: 700;
}
.ct-cirka-size-40 {
  font-size: 40px;
  font-family: "Cirka-Bold", sans-serif;
  font-weight: 700;
}
.ct-text-size-80 {
  font-size: 80px;
  font-family: "Gilroy-Bold", sans-serif;
  font-weight: 600;
}
.ct-text-size-60-Bold {
  font-size: 60px !important;
  font-family: "Gilroy-Bold", sans-serif !important;
  font-weight: 600;
}
.ct-text-size-25 {
  font-size: 25px;
  font-family: "Gilroy-Bold", sans-serif;
  font-weight: 600;
}
.ct-text-size-50 {
  font-size: 50px;
  font-family: "Gilroy-Bold", sans-serif;
  font-weight: 600;
}
.ct-text-size-24 {
  font-size: 24px;
  font-family: "Gilroy-SemiBold", sans-serif;
  font-weight: 500;
}
.ct-text-size-40 {
  font-size: 40px;
  font-family: "Gilroy-Bold", sans-serif;
  font-weight: 400;
  color: black;
}
.ct-text-size-30-bold {
  font-size: 30px !important;
  font-family: "Gilroy-Bold", sans-serif !important;
  font-weight: 400;
  color: black;
}
.ct-text-size-20-bold {
  font-size: 20px !important;
  font-family: "Gilroy-Bold", sans-serif !important;
  font-weight: 400;
  color: black;
}
.ct-text-size-40-gray {
  font-size: 40px;
  font-family: "Gilroy-Bold", sans-serif;
  font-weight: 400;
  color: gray;
}
.ct-text-size-16-gray {
  font-size: 16px;
  font-family: "Gilroy-SemiBold", sans-serif;
  font-weight: 400;
  color: gray;
}
.ct-text-size-20 {
  font-size: 20px;
  font-family: "Gilroy-Bold", sans-serif;
  font-weight: 400;
}
.ct-text-size-26 {
  font-size: 26px;
  font-family: "Gilroy-Bold", sans-serif;
  font-weight: 400;
}
.ct-text-size-30 {
  font-size: 30px !important;
  font-family: "Gilroy-Bold", sans-serif;
  font-weight: 400;
  color: #ffffff;
}
.ct-text-size-20 {
  font-size: 20px;
  font-family: "Gilroy-SemiBold";
  font-weight: 500;
}
.ct-text-size-18 {
  font-size: 18px;
  font-family: "Gilroy-SemiBold";
  font-weight: 400;
  color: gray;
}
.ct-text-size-20 {
  font-size: 20px;
  font-family: "Gilroy-SemiBold";
  font-weight: 400;
  color: gray;
}
.ct-text-size-20-black {
  font-size: 20px;
  font-family: "Gilroy-SemiBold";
  font-weight: 400;
  color: rgb(0, 0, 0);
}
.ct-text-size-24 {
  font-size: 24px;
  font-family: "Gilroy-SemiBold";
  font-weight: 400;
  color: rgb(0, 0, 0);
}
.ct-text-size-16 {
  font-size: 16px;
  font-family: "Gilroy-SemiBold";
  font-weight: 400;
}
.ct-text-size-14 {
  font-size: 14px;
  font-family: "Gilroy-medium", sans-serif;
  font-weight: 400;
  letter-spacing: 1px;
}
.ct-text-size-14-white {
  font-size: 14px;
  font-family: "Gilroy-medium", sans-serif;
  font-weight: 400;
  color: #fff;
}
.ct-text-size-14-grey {
  font-size: 14px;
  font-family: "Gilroy-medium", sans-serif;
  font-weight: 400;
  color: #B3B3B3;
}
.ct-text-size-11 {
  font-size: 11px;
  font-family: "Gilroy-medium", sans-serif;
  font-weight: 400;
}
.ct-text-size-14-gray {
  font-size: 14px;
  font-family: "Gilroy-medium", sans-serif;
  font-weight: 400;
  letter-spacing: 1px;
  color: gray;
}

.ct-cirka-size-25 {
  font-size: 25px !important;
  font-family: "Cirka-Bold", sans-serif;
  font-weight: 600;
}
.ct-text-26 {
  font-size: 26px;
}
.ct-text-30 {
  font-size: 50px !important;
}
.ct-text-20 {
  font-size: 20px;
}
.ct-text-24 {
  font-size: 24px;
}
.ct-text-18 {
  font-size: 18px;
}
.ct-text-weight-600 {
  font-weight: 600 !important;
}
.ct-text-weight-500 {
  font-weight: 500;
}
.ct-text-16 {
  font-size: 16px;
}
.ct-cirka-size-50 {
  font-size: 50px;
  font-family: "Cirka-Bold", sans-serif;
  font-weight: 600;
}
.ct-cirka-size-26 {
  font-size: 26px;
  font-family: "Cirka-bold";
  font-weight: 700;
  color: #fff;
}
.ct-gilroy-semibold {
  font-family: "Gilroy-SemiBold", sans-serif;
}
.ct-text-13 {
  font-size: 13px !important;
}
.ct-text-14 {
  font-size: 14px !important;
}

/* Color */

.ct-bg-gray {
  background: #fff;
}
.ct-text-gray {
  color: rgba(255, 255, 255, 0.7);
}
.ct-text-light {
  color: #fff !important;
}
.ct-bg-light {
  background-color: #fff;
}
.ct-bg-dark {
  background-color: #000;
}
.ct-text-green {
  color: #76ffc6;
}
/* Text Align */

.ct-text-left {
  text-align: left;
}
.ct-text-center {
  text-align: center;
}
.ct-text-right {
  text-align: right;
}
.ct-text-warning {
  color: #ff8761;
}

/* Border Radius */

.ct-border-radius-10 {
  border-radius: 10px;
}
.ct-border-radius-20 {
  border-radius: 20px;
}
.ct-border-radius-15 {
  border-radius: 15px;
}
.ct-border-radius-50 {
  border-radius: 50px;
}

/* Button */

.event-form-next-btn {
  background: #c0bebe;
  color: #000;
  border: none;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 600;
  width: 100%;
  padding: 15px;
  font-family: "Gilroy-Bold", sans-serif;
}
.verify-btn {
  color: #fff;
  border: none;
  padding: 14px 35px;
  border-radius: 10px;
  font-size: 13px;
  font-weight: 600;
  margin-left: 15px;
  cursor: pointer;
  font-family: "Gilroy-Bold", sans-serif;
}

.next-btn-small {
  background: #fff;
  color: #000;
  border: none;
  border-radius: 10px;
  font-size: 15px;
  font-weight: 600;
  font-family: "Gilroy-Bold", sans-serif;
  padding: 15px 35px;
}
.next-btn-small-dark {
  background: linear-gradient(90deg, #ee36ff 0%, #ff8762 100%) !important;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 600;
  font-family: "Gilroy-Bold", sans-serif;
  padding: 15px 35px;
}
.apply-coupon-btn-small {
  background: #8a8a8a !important;
  color: rgb(196 195 195) !important;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  font-family: "Gilroy-Bold", sans-serif;
  padding: 10px 25px;
}
.apply-coupon-btn-smal-gradient {
  background: linear-gradient(90deg, #ee36ff 0%, #ff8762 100%) !important;
  color: #fff !important;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  font-family: "Gilroy-Bold", sans-serif;
  padding: 10px 25px;
}
.next-btn-small-outline {
  color: #fff;
  background: transparent;
  border: 1px solid #fff;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 600;
  padding: 15px 35px;
  font-family: "Gilroy-Bold", sans-serif;
}

.back-btn-small-outline {
  color: #8a8a8a;
  background: transparent;
  border: 1px solid #8a8a8a;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 600;
  padding: 9px 27px;
  font-family: "Gilroy-Bold", sans-serif;
}
.delete-btn-small {
  background: #dc3545;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 600;
  font-family: "Gilroy-Bold", sans-serif;
  padding: 10px 27px;
}
.ct-banner-icon {
  font-size: 25px;
  color: #000;
}

/* Media Query */

@media (min-width: 500px) and (max-width: 1024px) {
  .lg-mx-5 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .web-full-height {
    height: 100vh;
  }
  .event-first-section {
    width: 100% !important;
  }
  .event-second-section {
    width: 100% !important;
  }
  .ws-card-first {
    margin-top: 40px;
    width: 100% !important;
  }
  .ws-card-second {
    width: 100% !important;
  }
  .ct-display-flex-mbl-unset {
    display: flex !important;
    flex-direction: column-reverse;
  }
  .ct-text-size-40 {
    font-size: 30px !important;
  }
  /* .ct-text-size-30 {
    font-size: 0px !important;
  } */
  .ct-ws-d-flex-center {
    display: unset !important;
    justify-content: center;
  }
  .ct-d-flex-justify-center {
    display: unset !important;
    justify-content: space-between;
  }
  .ws-first-section {
    width: 100% !important;
  }
  .ws-second-section {
    width: 100% !important;
  }
  .lg-px-5 {
    padding-left: 0px;
    padding-right: 0px;
  }

}

@media (min-width: 992px) and (max-width: 1024px) {
  .event-second-section {
    width: 50%;
  }

  .event-first-section {
    width: 50%;
  }
  .ct-display-flex-mbl {
    display: flex;
  }
  .w-600px {
    width: 400px;
  }
  .ct-width-80 {
    width: 100% !important;
  }
  .ct-width-10 {
    width: unset !important;
  }
  .ct-width-50 {
    width: 60% !important;
  }
  .ct-width-25 {
    width: 15% !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .ct-d-flex-justify-center-web {
    display: block !important;
  }
  .ct-display-flex-mbl {
    display: flex;
  }
  .event-second-section-total {
    width: 75%;
  }
  .ct-width-80 {
    width: 100% !important;
  }
  .ct-width-10 {
    width: unset !important;
  }
}
@media (max-width: 599px) {
  .ct-display-flex-mbl-unset {
    display: flex !important;
    flex-direction: column-reverse;
  }
  .apply-now-fill {
    background: linear-gradient(90deg, #ee36ff 0%, #ff8762 100%);
    padding: 15px 40px;
    border-radius: 10px;
    text-decoration: none;
    color: #fff !important;
    font-size: 18px !important;
    font-family: "Gilroy-Medium", sans-serif;
    font-weight: 600;
  }
  .ct-text-size-70 {
    font-size: 40px;
    font-family: "Gilroy-Bold", sans-serif;
    font-weight: 600;
  }
  .congratulations-img {
    width: 70px !important;
  }
  .ct-mbl-display-none {
    display: none;
  }
  .ct-text-size-25 {
    font-size: 25px;
  }
  .ct-d-flex-end {
    display: flex;
    justify-content: center !important;
  }
  .ct-display-flex-mbl {
    display: unset !important;
  }
  .event-first-section {
    width: 100% !important;
  }
  .event-second-section {
    width: 100% !important;
  }
  .ct-coupon-border {
    border: 1px solid #3b3b3b;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding-left: 10px;
    padding-top: 20px;
    border-radius: 10px;
  }
  .web-full-height {
    height: unset !important;
  }
  .ws-card-first {
    margin-top: 40px !important;
    width: 100% !important;
  }
  .ws-card-second {
    width: 100% !important;
  }
  .ct-text-size-40 {
    font-size: 23px !important;
  }
  .ct-text-size-30 {
    font-size: 20px !important;
  }
  .lg-mx-5 {
    margin-left: 0px;
    margin-right: 0px;
  }
  /* .work-shop-background-image {
    padding: 15px !important;
  } */
  .ct-mbl-border-radius-15 {
    border-radius: 15px;
  }
  .ct-mbl-text-center {
    text-align: center;
  }
  .lg-px-5 {
    padding-left: 10px;
    padding-right: 10px;
  }
  .ct-d-flex-justify-center-web {
    display: block !important;
  }
  .w-450px {
    width: 100% !important;
  }
  .event-second-section {
    width: unset !important;
  }
  .event-first-section {
    width: unset !important;
  }
  .ct-input-box {
    border-radius: 10px;
    background-color: #000000;
    border: 1px solid #c5c8ca;
    padding: 12px;
    font-size: 16px;
    width: 100% !important;
    color: #000;
  }
  .ct-textarea-box {
    border-radius: 10px;
    border: 1px solid #c5c8ca;
    padding: 12px;
    font-size: 16px;
    width: 100% !important;
    height: 100px;
    color: #fff;
  }
  .event-form-next-btn {
    background: #c0bebe;
    color: #000;
    border: none;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 600;
    width: 100%;
    padding: 15px;
  }
  .ws-form-next-btn {
    background: #ffffff;
    color: #000;
    border: none;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 600;
    width: 100%;
    padding: 15px;
  }
  .phone-no-input {
    border-radius: 10px;
    padding: 12px;
    font-size: 16px;
    width: 100%;
    background-color: #000000;
    border: 1px solid #37393a;
    color: #b3b3b3;
  }
  .ct-input-card {
    border-radius: 10px;
    padding: 12px;
    font-size: 12px;
    width: 100%;
    background-color: #000000;
    border: 1px solid #37393a;
    color: #fbfbfb;
  }
  .react-datepicker-wrapper {
    width: 100% !important;
  }
  .ct-state-width {
    width: 100% !important;
  }
  .web-display-flex {
    display: unset !important;
  }
  .ct-ml-20 {
    margin-left: 0px !important;
    margin-top: 15px !important;
  }
  .ct-display-inline-block {
    display: unset !important;
  }
  .verify-btn {
    color: #fff;
    border: none;
    padding: 14px 35px;
    border-radius: 5px;
    font-size: 13px;
    font-weight: 600;
    margin-left: 0px !important;
    margin-top: 10px;
    cursor: pointer;
  }
  .mbl-my-4 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .w-500px {
    width: 367px;
  }
  .w-600px {
    width: 100% !important;
  }
  .nominee-left-5 {
    margin-left: 0 !important;
    padding-left: 0px;
  }
  .ct-cirka-size-50 {
    font-size: 30px !important;
  }
  .ct-text-24 {
    font-size: 20px !important;
  }
  .ct-text-20 {
    font-size: 17px !important;
  }
  .auto-progress-bar-width {
    width: 65%;
  }
  .auto-progress-bar-width-70 {
    width: 65%;
  }
  .auto-complete-width {
    width: 100% !important;
  }
  .dialog-custom-input {
    width: auto;
    display: flex;
    justify-content: center;
    OtpInput {
      background-color: #1a1a1a !important;
      font-size: 30px;
    }
  }
  .dialog-custom-rules {
    width: 800px;
  }
  .dob-years-badge {
    position: absolute !important;
    right: 10px !important;
    top: unset !important;
    margin-top: 5px !important;
  }
  .ct-width-400 {
    width: unset !important;
  }
  .ct-text-size-80 {
    font-size: 40px !important;
  }
  .ct-text-size-50 {
    font-size: 30px !important;
    font-weight: 600;
    font-family: "Gilroy-Bold", sans-serif;
  }
  .ipad-bottom {
    position: relative;
    bottom: -14px !important;
  }
  .ct-width-70 {
    width: 100% !important;
    /* text-align: center; */
  }
  .ct-grid {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 20px;
  }
  .ct-width-30 {
    width: 100% !important;
    text-align: center;
  }
  .image-card-ws {
    width: 250px !important;
    border-radius: 20px;
    display: none;
  }
  .image-card-ws-card {
    width: 220px;
  }
  .ct-worksho-shedule {
    justify-content: center !important;
  }
  .ct-mbl-flex-start {
    justify-content: flex-start !important;
  }
  .mbl-mb-20 {
    margin-bottom: 20px !important;
  }
}

@media (min-width: 320px) and (max-width: 361px) {
  .w-500px {
    width: 320px !important;
  }
  .w-600px {
    width: 320px !important;
  }
}

@media (min-width: 992px) and (max-width: 1024px) {
  .nominee-left-5 {
    margin-left: 0rem;
  }
}

@media (max-width: 500px) {
  .ct-display-flex-bottom {
    display: unset !important;
  }
  .ct-bottom-width-50 {
    width: unset !important;
  }
  .qr-code-position {
    position: relative;
    top: 0px !important;
  }
  .ct-space-evenly {
    display: unset !important;
    justify-content: center !important;
  }
}

/* Badge */

.dob-years-badge {
  position: relative;
  right: 105px;
  top: 12px;
  margin-top: 5px !important;
}
/* Custom Datepicker */

.datepicker-wrapper {
  .react-datepicker {
    background-color: white;
    color: black;
  }

  .react-datepicker__input-container input {
    color: black;
  }

  .react-datepicker__month-container {
    color: black;
  }

  .react-datepicker__day-names,
  .react-datepicker__week {
    color: black;
  }
}

/* Material UI Custom */

.css-17282r-MuiLinearProgress-bar1 {
  background: linear-gradient(90deg, #ee36ff 80%, #ff8762 100%) !important;
}
.css-10jyip1-MuiLinearProgress-root,
.css-uhb5lp {
  background-color: #fff !important;
}
.MuiAutocomplete-hasPopupIcon.css-5mgh87-MuiAutocomplete-root
  .MuiOutlinedInput-root,
.MuiAutocomplete-hasClearIcon.css-5mgh87-MuiAutocomplete-root
  .MuiOutlinedInput-root {
  border: 1px solid #3d3d3d;
}
.css-qzbt6i-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-popupIndicator {
  color: #ffffff !important;
}
.css-1glvl0p-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator,
.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root,
Autocomplete,
Select,
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select,
.css-fvipm8 {
  color: #010101 !important;
  font-family: "Gilroy-Regular", sans-serif !important;
}
.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  background-color: #fff !important;
}
.react-date-picker__wrapper {
  border: none !important;
}
.react-date-picker__inputGroup__input {
  pointer-events: none !important;
}
.css-2dvya8 .MuiOutlinedInput-root .MuiAutocomplete-input,
.css-edpqz1,
.css-uge3vf,
.css-segi59,
Autocomplete,
Select,
.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
  color: #fff !important;
}
.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root,
.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  padding: 0px !important;
}
.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-18nc3u2
  .MuiOutlinedInput-root,
.MuiSvgIcon-root,
.css-qiwgdb.css-qiwgdb.css-qiwgdb {
  color: #ffffff !important;
}
.css-9veiix-MuiButtonBase-root-MuiCheckbox-root .MuiSvgIcon-root {
  font-size: 28px !important;
  color: #ee35ff !important;
}

/* Pointer */

.ct-pointer {
  cursor: pointer;
}

.else-number-bg {
  background: #f2817b;
  padding: 7px 13px;
  border-radius: 6px;
}
.landing-text-top {
  padding-top: 100px;
}
.ct-px-30 {
  padding-left: 30px;
  padding-right: 30px;
}
.landing-gradient-text {
  background-image: linear-gradient(to right, #ee36ff 20%, #ff8762 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-size: 35px;
  font-weight: 600;
}
.banner-apply-now-btn {
  padding-top: 60px !important;
}
.css-1e1x4vv-MuiSkeleton-root,
.css-ayl0v9-MuiSkeleton-root,
.css-1l6af8c-MuiSkeleton-root {
  background-color: rgb(255 255 255 / 11%) !important;
}
.ct-pl-20 {
  padding-left: 20px;
}
.ct-pad-20 {
  padding: 30px;
  background-color: white;
}
.pt-3-5 {
  padding-top: 3.5rem !important;
}
.testimonal-px {
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.truncate-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ct-marign-right {
  margin-right: 20px;
}
.rules-border {
  background: linear-gradient(90deg, #ee36ff 0%, #ff8762 100%);
  padding: 14px 1px !important;
  border-radius: 5px;
  text-decoration: none;
  color: "#FFF" !important;
  font-size: 16px !important;
  font-family: "Gilroy-Medium", sans-serif;
  font-weight: 600;
}
.rules-border-span {
  padding: 17px 16px;
  color: rgb(255, 255, 255);
  position: relative;
  background: black;
  border-radius: 5px;
  overflow: hidden;
  font-size: 16px !important;
  font-family: "Gilroy-Regular", sans-serif;
  font-weight: 500;
}
.rules-border-now-border {
  border-radius: 85px;
  overflow: hidden;
  width: 120px;
  height: 46px;
  background: linear-gradient(
    90deg,
    rgb(237, 48, 255) 0%,
    rgb(255, 135, 97) 100%
  );
  display: flex;
  align-items: center;
  justify-content: center;
}
.video-height {
  height: 100%;
  width: 100%;
  position: absolute;
  object-fit: cover;
}
.delete-dialog-bg {
  .css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop,
  .css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop {
    background-color: #0000000a !important;
  }
}
.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  box-shadow: unset !important;
}
.css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop {
  background-color: #0000000a !important;
}
.ct-home-title-100 {
  font-size: 100px;
  font-weight: 600;
  font-family: "Gilroy-Bold" !important;
}
.ct-home-para {
  font-size: 22px;
  font-weight: 500;
  color: #fff;
}
.ct-d-flex-justify-center-home {
  display: flex;
  justify-content: space-between;
}
.ct-p-5 {
  padding: 3rem;
}
.ct-p-4 {
  padding: 2rem;
}
.ct-text-light-gray {
  color: #b1b1b1;
}
.disabled-input {
  color: #b4b3b3 !important;
}
.hidden-section {
  display: none;
}
.ct-sub-banner-title {
  font-size: 25px !important;
  font-weight: 700 !important;
  color: #ffc107d4;
  font-family: "Gilroy-Bold" sans-serif !important;
}
.animation-text {
  text-align: center;
  h3 {
    color: #fff;
    font-size: 92px;
    text-transform: uppercase;
    font-weight: 700;
    font-family: "Josefin Sans", sans-serif;
    background: linear-gradient(to left, #ffeca8 10%, #ffc107d4 50%);
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 1.5s linear infinite;
    display: inline-block;
  }
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

@keyframes borderTopAnimation {
  from {
    border-image-width: 0;
  }
  to {
    border-image-width: 1;
  }
}

.border-top-animation {
  border-top: 1px solid transparent;
  border-image-source: linear-gradient(
    90deg,
    rgba(42, 45, 47, 0) 0.01%,
    #d9d9d9 49.69%,
    rgba(42, 45, 47, 0) 100%
  );
  border-image-slice: 1;
  animation: borderTopAnimation 2s forwards;
}
.text-gold {
  color: #ffeca8;
}
.web-jc-center {
  justify-content: center;
}
.web-mr-30 {
  margin-right: 30px;
}
.sponsors-second {
  position: relative;
  top: 17px;
}
.sponsors-third {
  position: relative;
  top: 20px;
}
.sponsors-five {
  position: relative;
  top: 22px;
}
.sponsors-six {
  position: relative;
  top: 22px;
}
.sponsors-seven {
  position: relative;
  top: 17px;
}
.sponsors-eight {
  position: relative;
  top: 14px;
}
.sponsors-nine {
  position: relative;
  bottom: 22px;
}
.sponsors-ten {
  position: relative;
  top: 34px;
}
.web-mb-30 {
  margin-bottom: 40px;
}
.video-card {
  /* width: 60% !important; */
  /* border: 1px solid #ddd; */
  /* border-radius: 4px; */
  overflow: hidden;
  margin: 10px;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px;
  padding: 20px;
}
.testimonial-video-card {
  background-color: #1c1c1e;
  border-radius: 15px;
}

.video-thumbnail {
  /* width: 100%; */
  height: 200;
  position: relative;
}

.video-thumbnail img {
  border-radius: 30px;
  border-top-right-radius: 30px;
}

.video-info {
  padding: 10px;
  display: flex;
  align-items: center;
}

.video-info .avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.testimonial-details {
  background-color: #1c1c1e;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  padding-bottom: 10px;
}
.testimonial-quote {
  font-size: 14px !important;
  font-weight: 600;
  color: #fff;
}

.testimonail-padding {
  padding: 30px;
}
.slick-dots li button:before {
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "•";
  text-align: center;
  opacity: 0.25;
  color: white !important;
  -webkit-font-smoothing: antialiased;
}
.slider-container {
  position: relative;
}

.testimonial-data {
  position: relative;
}

.custom-arrow {
  position: absolute;
  bottom: -50px;
  width: 40px;
  height: 40px;
  background-color: transparent; /* Arrow background color */
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px; /* Adjust as needed */
  color: #fff; /* Arrow color */
  cursor: pointer;
  z-index: 1;
  border: 1px solid #fff;
}

.custom-arrow-prev {
  left: 47%;
  transform: translate(-50%, -50%);
}

.custom-arrow-next {
  left: 53%;
  transform: translate(-50%, -50%);
}

.slick-slide {
  display: flex;
  flex-direction: column;
  height: auto;
}

.video-card {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.testimonial-video-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.testimonial-details {
  flex-grow: 1;
}
.cardHeight {
  height: 231px;
}
.join-community-now-fill {
  background: #06c270;
  padding: 18px 26px;
  border-radius: 5px;
  text-decoration: none;
  color: "#FFF" !important;
  font-size: 16px !important;
  font-family: "Gilroy-Medium", sans-serif;
  font-weight: 600;
}

.image-card-ws-card {
  width: 260px;
  height: 310px;
  object-fit: cover;
}

/* Workshop Css */

/* 
.work-shop-background-image {
  width: 100%;
  max-width: 100vw;
  padding: 30px;
  background-color: #010101;
}
.ct-banner-empty-space {
  width: 10% !important;
}
.image-card-ws {
  width: 388px;
}
.image-card-ws-card {
  width: 260px;
  height: 310px;
  object-fit: cover;
}
.ct-pr-30 {
  padding-right: 30px;
}
.ct-width-70 {
  width: 70%;
}
.ct-width-30 {
  width: 30%;
}
.ipad-bottom {
  position: relative;
  bottom: 13px;
}
.ct-space-evenly {
  display: flex;
  justify-content: space-evenly;
}
.workshop-date-time-card {
  width: 30%;
  border: 1px solid rgba(206, 206, 206, 1);
  border-radius: 17px;
  display: flex;
  background-color: white;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}
.ct-mentor-img-size {
  width: 300px;
}
.mentor-display-flex {
  display: flex;
  justify-content: center;
}
.faqs-display-flex {
  display: flex;
  justify-content: center;
}
.ct-mr-30 {
  margin-right: 30px;
}
.ct-width-80 {
  width: 80%;
}
.ct-width-50 {
  width: 50%;
}
.ct-width-10 {
  width: 10%;
}
.ct-width-25 {
  width: 25%;
}
.ws-highlights-center {
  display: flex;
  justify-content: center;
}
.highlights-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.highlight-card {
  flex: 1 1 100%;
  box-sizing: border-box;
  margin: 10px;
  padding: 20px;
  border: 1px solid #ccc;
  background: #fff;
  border-radius: 8px;
  text-align: left;
  box-shadow: 0px 4px 10px 0px #9a9a9a40;
} */
@media (min-width: 768px) {
  .highlight-card {
    flex: 1 1 calc(50% - 20px);
  }
}

@media (min-width: 1024px) {
  .highlight-card {
    flex: 1 1 calc(25% - 20px);
  }
}
.next-btn {
  background: #a3a3a3;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  width: 60%;
  padding: 15px;
  margin-top: 20px;
}
.pc-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.pay-completed-bg-img {
  width: 100%;
  height: 100%;
  /* max-width: 100vw; */
  /* max-height: 100vh; */
  background-image: url("./assets/BGart.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding-left: 50px;
  padding-right: 50px;
}
.ct-paymentcompleted {
  background-color: #ffffff;
  padding: 40px;
  width: 100%;
  max-width: 100vw;
  border: 1px solid gray;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.ct-congratulation-text {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}
.ct-availability {
  margin-bottom: 0rem;
  margin-top: 1px;
}
.ct-download {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.ct-more-details {
  padding-bottom: 15px;
  color: #484848;
}
.QRcode {
  width: 250px;
  height: 250px;
}
.ct-paymentsuccess {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 50px;
  padding: 30px;
}
.ct-QRcode-box {
  width: 80%;
  border: 1px solid gray;
  border-radius: 15px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.payment-second-section {
  width: 100% !important;
  margin-bottom: 20px;
  margin-right: 0px !important;
  margin-top: 20px !important;
}
.ct-ws-details {
  width: 30%;
  margin-right: 30px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-bottom: 180px;
  gap: 30px;
}
.Copy {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-top: 60px;
}
.Terms {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 40px;
}
.Store {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 20px;
}
.playstore {
  width: 131px;
  height: 60px;
}
.appstore {
  width: 130px;
  height: 41px;
}
.card-custom{
  height:160px ;

  padding: 15px;
  border-radius: 10px;
}
@media (min-width: 678px) and (max-width: 1024px) {
  .phone-no-input {
    width: 50%;
  }
  .workshop-card {
    max-width: 100% !important;
  }
  .ct-input-card {
    width: 100%;
  }
  .next-btn {
    width: 80%;
  }
  .ct-flex-row {
    display: flex;
    flex-direction: column;
    padding: 30px;
    margin-top: 20px;
    gap: 20px;
  }
  .workshop-first-section {
    width: 100%;
    margin-right: 0px;
    border: 1px solid gray;
    border-radius: 30px;
  }
  .workshop-second-section {
    width: 100%;
    margin-right: 0px;
    border: 1px solid gray;
    border-radius: 30px;
  }
  .ct-Filter-container {
    padding: 0px 20px;
    gap: 25px;
    flex-direction: column;
    align-items: center;
  }

  .ct-filter-pointer {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }
}
@media (max-width: 500px) {
  .phone-no-input {
    width: 80%;
  }
  .ct-input-card {
    width: 100%;
  }
  .next-btn {
    width: 80%;
  }
  .card-custom{
    height:200px;
    padding: 15px;
    border-radius: 10px;
  }
  .ct-Filter-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 1px 50px;
    margin: 0 auto;
    align-items: flex-end;
    gap: 35px;
  }
  .ct-flex-row {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px;
    margin-top: 40px;
    gap: 20px;
  }
  .workshop-first-section {
    width: 100%;
    margin-right: 0px;
    border: 1px solid gray;
    border-radius: 30px;
  }
  .workshop-second-section {
    width: 100%;
    margin-right: 0px;
    border: 1px solid gray;
    border-radius: 30px;
  }

  .ct-paymentcompleted {
    background-color: #ffffff;
    padding: 40px;
    width: 100%;
    max-width: 100vw;
    border: 1px solid gray;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .ct-congratulation-text {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
  }
  .popper-congratulations {
    width: 90px;
    height: 90px;
  }
  .pc-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .Download {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .ct-title {
    align-items: flex-end;
  }
  .ct-success {
    margin-bottom: 0rem;
    margin-top: 1px;
    padding-bottom: 10px;
  }
  .ct-availability {
    margin-bottom: 0rem;
    margin-top: 1px;
  }
  .ct-download {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .ct-more-details {
    padding-bottom: 15px;
    color: #484848;
  }
  .QRcode {
    width: 250px;
    height: 250px;
  }
  .ct-paymentsuccess {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 50px;
    padding: 30px;
  }
  .ct-QRcode-box {
    width: 80%;
    border: 1px solid gray;
    border-radius: 15px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  .payment-second-section {
    width: 100% !important;
    margin-bottom: 20px;
    margin-right: 0px !important;
    margin-top: 20px !important;
  }
  .ct-ws-details {
    width: 30%;
    margin-right: 30px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-bottom: 180px;
    gap: 30px;
  }
  .Store {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  .playstore {
    width: 180px;
    height: 85px;
  }
  .appstore {
    width: 180px;
    height: 55px;
  }
  .pc-2 {
    display: flex;
    flex-direction: column;
  }
  .Copy {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin-top: 60px;
  }
  .Terms {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 40px;
  }
  .ct-filter-pointer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .ct-Filter-Text {
    font-size: 16px !important;
    font-family: "Gilroy-Medium", sans-serif;
    font-weight: 400;
    color: rgb(90, 90, 90);
  }
}
@media (min-width: 678px) and (max-width: 1024px) {
  .ct-paymentsuccess {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .ct-QRcode-box {
    height: 50%;
  }
  .ct-ws-details {
    height: 50%;
    padding-bottom: 5px;
    width: 80%;
  }
  .Copy {
    gap: 5px;
  }
  .Terms {
    gap: 15px;
  }
}
@media (max-width: 500px) {
  .pay-completed-bg-img {
    padding: 20px !important;
  }
  .ct-paymentcompleted {
    padding: 20px;
  }
  .ct-cirka-size-60 {
    font-size: 34px;
  }
  .ct-congratulation-text {
    gap: 40px;
  }
  .popper-congratulations {
    width: 50px;
    height: 50px;
  }
  .ct-text-size-20 {
    font-size: 16px;
  }
  .ct-text-size-40-gray {
    font-size: 26px;
  }
  .ct-text-size-24 {
    font-size: 16px;
  }
  .ct-paymentsucess {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .ct-paymentsuccess {
    display: flex;
    flex-direction: column;
    padding: 10px;
  }
  .ct-QRcode-box {
    width: 100%;
    padding: 20px;
    gap: 10px;
  }
  .Store {
    display: flex;
    flex-direction: column;
  }
  .ct-ws-details {
    padding-bottom: 5px;
  }
  .Copy {
    gap: 5px;
    display: flex;
    flex-direction: column;
  }
  .Terms {
    gap: 15px;
  }
  .ct-text-size-16-gray {
    font-size: 10px;
  }
  .QRcode {
    width: 200px;
    height: 200px;
  }
  .containers {
    z-index: 1000;
  }
}
.ct-coupon-tag {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid gray;
  border-radius: 8px;
  padding: 20px;
  gap: 20px;
}
.ct-coupon-apply {
  width: 100%;
}
.coupon-no-input {
  width: 100%;
  height: 10%;
  border: 1px solid gray;
  border-radius: 8px;
  padding: 8px 55px 8px 10px;
}
.apply-coupon-btn {
  position: relative;
  border: none;
  left: 380px;
  bottom: 32px;
}
.ct-logo {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.ct-total-amt {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.complete-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 8px;
  position: relative;
  top: 70px;
  background: linear-gradient(90deg, #ee35ff 0%, #ff8761 100%);
  padding: 10px 20px;
  color: white;
}
.wsFeedbackPage {
  display: flex;
  flex-direction: row;
}

.Offeringpage {
  width: 65%;
}
.offeringcard {
  width: 50%;
}
@media (min-width: 678px) and (max-width: 1024px) {
  .complete-btn {
    top: 10px;
  }
  .apply-coupon-btn {
    left: 340px;
  }
}
@media (max-width: 500px) {
  .apply-coupon-btn {
    left: 210px;
  }
  .complete-btn {
    top: 10px;
  }
  .ct-coupon-tag {
    gap: 1px;
    width: 100%;
  }
  .wsFeedbackPage {
    display: flex;
    flex-direction: column;
  }
  .Offeringpage {
    width: 500px;
  }
}

.ct-ws-badge-gray-small {
  background: #f2f2f2;
  padding: 6px 10px;
  border-radius: 5px;
  color: #000;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 5px;
}
.ct-border-light-gray {
  border: 1px solid #e5e1e1;
  border-radius: 15px;
}
.ws-first-section {
  width: 49%;
}
.ct-mbl-flex-start {
  display: flex;
  gap: 10px;
}
.ct-web-border-radius-15 {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}
.ct-ws-d-flex-center {
  display: flex;
  justify-content: center;
}

/* Ensure the container is flexible */
.workshop-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; /* Ensure space between the cards */
}

/* Default: Two cards per row on larger screens */
.workshop-card {
  flex: 1 1 calc(50% - 20px); /* 50% width minus space for gap */
  max-width: calc(50% - 20px);
  margin-bottom: 20px;
}

/* For screens smaller than 768px (mobile and tablets), set one card per row */
@media screen and (max-width: 768px) {
  .workshop-card {
    flex: 1 1 100%;
    max-width: 100%;
  }
}
.ct-gap-25 {
  gap: 25px;
}
.ct-ws-search-position {
  position: relative;
  margin-top: 23px;
  margin-left: 50px;
}
.ct-ws-badge-gray {
  background: #f2f2f2;
  padding: 10px 20px;
  border-radius: 5px;
  color: #000;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 10px;
}
.ct-gap-10 {
  gap: 10px;
}
.ws-card-first {
  width: 60%;
}
.ws-card-second {
  width: 40%;
}
.ct-bg-white {
  background: #000000;
  padding: 30px;
  gap: 10px;
  border: 1px solid #3b3b3b;
  border-radius: 10px;
}
.ct-margin-20 {
  margin-right: 20px;
}
.ct-display-flex-mbl-unset {
  display: flex;
}
.web-full-height {
  height: 110vh;
}
.ct-ws-apply-coupon {
  position: relative;
  right: 53px;
  top: 13px;
}
.ct-ws-remove-coupon {
  position: relative;
  right: 70px;
  top: 13px;
}
@media (min-width: 1366px) {
  .ct-web-position-relative {
    position: relative;
    height: 100%;
  }
  .ct-web-position-absolute {
    position: absolute;
    bottom: 1px;
    width: 100%;
  }
  .ct-web-pl-30 {
    padding-left: 40px;
  }
}
.ct-justify-space-between {
  justify-content: space-between;
}
.ct-web-width-90 {
  width: 90%;
}
.ct-coupon-border {
  border: 1px solid #3b3b3b;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding-left: 23px;
  padding-top: 40px;
  border-radius: 10px;
}
.ct-text-success {
  color: #06c270;
}
.ct-display-flex-mbl {
  display: flex;
}
.ct-d-flex-end {
  display: flex;
  justify-content: flex-end;
}
.ws-card-width-60 {
  justify-content: space-between;
}
.ct-py-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}
.ct-py-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}
.congratulations-img {
  width: 100px;
}
.header-nav {
  transition: background-color 0.3s ease;
  background-color: transparent; /* Transparent background initially */
}

.header-nav.sticky {
  background-color: #ffffff; /* White background on scroll */
  /* box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); */
}

.header-nav .nav-link {
  color: #000;
}

.header-nav.sticky .nav-link {
  color: #333;
}
/* .navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");

}
.navbar-dark .navbar-toggler {
  border: none;
  background-color: white;
} */

.registerbutton {
  border: "none";
}

@media (max-width: 768px) {
  .navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 1)' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  }
  .navbar-dark .navbar-toggler {
    border: none;
    background-color: rgb(255, 255, 255);
  }
  .navbar-collapse {
    background-color: white;
  }
  .navbar-collapse .nav-link {
    color: black !important;
  }
  .burger-menu {
    display: block;
  }
}
@media (max-width: 1200px) {
  .navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 1)' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  }
  .navbar-dark .navbar-toggler {
    border: none;
    background-color: white;
  }
  .navbar-collapse {
    background-color:#000000;
  }
  .navbar-collapse .nav-link {
    color: black !important;
  }
}
/* wsNavbar.css */
.header-nav {
  transition: all 0.3s ease-in-out;
}

.header-nav.sticky {
  background-color: #333;
  padding: 10px 0;
}

.navbar-dark .navbar-toggler {
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-collapse {
  justify-content: flex-end;
}

.navbar-nav .nav-item {
  margin-left: 20px;
}

.navbar-nav .nav-item .ct-pointer {
  cursor: pointer;
}

.navbar-nav .nav-item .ct-pointer:hover {
  color: #f0ad4e;
}

.custom-toggler.navbar-toggler {
  border-color: transparent;
}

.custom-toggler.navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}
.ct-text-size-90 {
  font-size: 98px;
  font-family: "Gilroy-Bold", sans-serif;
  font-weight: 600;
}
.ct-banner-22 {
  font-size: 22px !important;
  font-family: "Gilroy-Medium", sans-serif;
  font-weight: 400;
  padding: 0px 55px;
}
.Ws-banner-fill-small {
  background: linear-gradient(270deg, #ff8665, #ef37fd);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}
.ct-banner-Text {
  font-size: 28px !important;
  font-family: "Gilroy-Medium", sans-serif;
  font-weight: 400;
}
.ct-Banner-price {
  font-size: 60px !important;
  font-family: "Gilroy-Semibold", sans-serif;
  font-weight: 400;
}
.ct-compare-price {
  font-size: 30px !important;
  font-family: "Gilroy-Semibold", sans-serif;
  font-weight: 400;
}
.ct-Banner-mode {
  font-size: 20px !important;
  font-family: "Gilroy-Semibold", sans-serif;
  font-weight: 400;
}
.ct-Banner-part {
  font-size: 14px !important;
  font-family: "Gilroy-Semibold", sans-serif;
  font-weight: 400;
}
.ct-banner-22 {
  font-size: 22px !important;
  font-family: "Gilroy-Medium";
  font-weight: 400;
  padding: 0px 55px;
}
.work-shop-banner-background-image {
  background-image: url("./assets/bg-banner-img.png");
  background-repeat: no-repeat;
  background-size: cover;
}
/* .container-fluid {
  border-radius: 8px;
}
.container-fluid-work{
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
} */
.ct-banner-Text {
  font-size: 18px !important;
  font-family: "Gilroy-Medium", sans-serif;
  font-weight: 400;
}
.ct-banner-Text-28 {
  font-size: 28px !important;
  font-family: "Gilroy-Medium", sans-serif;
  font-weight: 400;
}
.ct-timer-size-32 {
  font-size: 32px;
  font-family: "Gilroy-Bold", sans-serif;
  font-weight: 600;
}
.Banner-timer-button {
  font-size: 18px !important;
  font-family: "Gilroy-Semibold", sans-serif !important;
}
.rounded {
  background-color: black;
  color: white;
}
.Timer-banner {
  background-image: url("./assets/Timer-bg.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.ct-Workshop-del {
  text-decoration: line-through;
}
.ct-WorkShop-size-20 {
  font-size: 20px;
  font-family: "Gilroy-Bold", sans-serif;
  font-weight: 400;
}
.Workshop-sub-title {
  font-size: 14px !important;
  font-family: "Gilroy-Regular", sans-serif !important;
}
.ct-text-regular-16 {
  font-size: 16px !important;
  font-family: "Gilroy-Regular", sans-serif !important;
}
.ct-Workshop-Learn-Banner {
  width: 80%;
}
.ct-category-sub-text {
  font-size: 24px !important;
  font-family: "Gilroy-SemiBold", sans-serif !important;
  font-weight: 600;
  color: rgba(217, 217, 217, 1);
}
.ct-banner-Text-14 {
  font-size: 14px !important;
  font-family: "Gilroy-Medium";
  font-weight: 400;
}
/* .ct-Workshop-Learn{
  display: flex;
  flex-direction: column;
  justify-content: center;
} */
.Review_card {
  margin: 1rem !important;
}
.ct-Text-semi-24 {
  font-size: 24px !important;
  font-family: "Gilroy-SemiBold", sans-serif !important;
  font-weight: 600;
}
.mentor_card {
  display: flex;
}
.Mentor_Links {
  width: 30px;
  height: 30px;
}
.ct-Workshop_mentor_img {
  width: 300px;
  height: 300px;
}
.ct-Text-semi-32 {
  font-size: 32px !important;
  font-family: "Gilroy-SemiBold", sans-serif !important;
  font-weight: 600;
}
.ct-Mentor-regular-20 {
  font-size: 16px;
  font-family: "Gilroy-Light" !important;
  font-weight: 400;
  color: #dedede;
}
.ct-text-size-20-Workshop {
  font-size: 20px;
  font-family: "Gilroy-SemiBold";
  font-weight: 500;
}
.ct-workshop-semi-16 {
  font-size: 16px !important;
  font-family: "Gilroy-SemiBold", sans-serif !important;
  font-weight: 600;
}
.Ct-Video-title {
  padding-left: 7rem;
}
.Ct-qa-items {
  max-width: 600px;
  margin-bottom: 140px;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 30;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: block !important;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.modal-overlay-Phone {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  align-items: center;
  justify-content: center;
  z-index: 1000;
  display: none !important;
  
}

.modal-content {
  background: white;
  padding: 20px;
  text-align: center;
  align-items: center;
  justify-content: flex-start;
  display: flex;
}
.video-Description{
  margin-left: 80px
}
.ct-phone-block {
  display: block;

}
.header-nav {
  transition: all 0.3s ease-in-out;
}

.header-nav.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

@media (max-width: 768px) {
  .ct-text-size-90 {
    font-size: 30px;
    font-family: "Gilroy-Bold", sans-serif;
    font-weight: 600;
  }
  .ct-phone-block {
    display: none;

  }
  .Ct-qa-items {
    max-width: 600px;
    margin-bottom: 80px;
  }

  .ct-Mentor-regular-20 {
    font-size: 12px;
    font-family: "Gilroy-Light" !important;
    font-weight: 400;
    color: #dedede;
  }
  .ct-text-size-20-Workshop {
    font-size: 14px;
    font-family: "Gilroy-SemiBold";
    font-weight: 500;
  }
  .ct-Workshop_mentor_img {
    width: 200px;
    height: 200px;
  }
  .video-Description{
    margin-left: 0px
  }
  .Mentor_Links_mobile {
    background-color: white;
    border: 1px solid white;
    border-radius: 4px;
    padding: 4px;
    width: 36px;
    height: 36px;
  }
  .mentor_card {
    display: flex;
    flex-direction: row;
  }
  .ct-Text-semi-24 {
    font-size: 18px !important;
    font-family: "Gilroy-SemiBold", sans-serif !important;
    font-weight: 600;
  }

  .Review_card {
    margin: none !important;
  }
  .ct-Workshop-Learn-Banner {
    width: 100%;
  }
  .ct-banner-display-block {
    display: none;
  }
  .ct-banner-Text-28 {
    font-size: 18px  !important;
    font-family: "Gilroy-Medium", sans-serif;
    font-weight: 400;
  }
  .ct-banner-Text {
    font-size: 15px !important;
    font-family: "Gilroy-Medium", sans-serif;
    font-weight: 400;
  }

  .ct-banner-22 {
    font-size: 14px !important;
    font-family: "Gilroy-Medium", sans-serif;
    font-weight: 400;
    padding: 0px 0px;
  }
  .ct-banner-14 {
    font-size: 16px !important;
    font-family: "Gilroy-Medium";
    font-weight: 400;
    padding: 0px 55px;
  }
  .ct-Price {
    display: none;
  }
  .ct-about-para-text {
    font-size: 12px;
    font-family: "Gilroy-Regular", sans-serif;
    font-weight: 400;
  }
  .ct-Banner-mode {
    font-size: 12px !important;
    font-family: "Gilroy-Semibold", sans-serif;
    font-weight: 400;
  }
  .ct-text-regular-16 {
    font-size: 14px !important;
    font-family: "Gilroy-Regular", sans-serif !important;
  }
  .modal-overlay-Phone {
    display: block !important;
    z-index: 1000;
  }
}
@media (min-width: 678px) and (max-width: 1024px) {
  .ct-Workshop_mentor_img {
    width: 200px;
    height: 200px;
    margin-left: 20px;
  }
  .ct-Workshop-Learn-Banner {
    width: 80%;
  }
}
.loading-gif {
  position: relative;
  top: 160px;
}
.ct-ws-feedback {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}
.card {
  display: "flex";
  flex-direction: "row";
}
.card-ws {
  width: 50%;
}
.ct-logo {
  width: 120px;
  height: 28px;
}

.ws-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}
.ws-container1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(270deg, #313131 0%, #000000 100%);
}
.ws-container2 {
  width: 60%;
  display: flex;
  flex-direction: row;
  background-color: yellow;
}
.ct-logo {
  width: 120px;
  height: 28px;
  display: flex;
}

.rating-session {
  width: 18px;
  height: 18px;
}
.ratings {
  position: relative;
  top: 8px;
}
.gradient-line {
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, #313131 0%, #000000 100%);
}
.wsfeedback {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.WorkShopCard {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row;
  background-color: black;
  gap: 20px;
}
.feedbackcard {
  width: 50%;
}
.expertPage {
  width: 100%;
  height: 100vh;
}
.OriginalPrice {
  text-decoration: line-through;
}
.fixed-side {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
}
.expert-details {
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}
.expertcard-rating {
  background-color: #ffffff0f;
  width: 70px;
  height: 28px;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.expertcard-sessions {
  background-color: #ffffff0f;
  width: 100px;
  height: 28px;
  align-items: center;
  border-radius: 15px;
}
.expertcard-skills {
  background-color: #ffffff0f;
  width: auto;
  height: 28px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border: 1px solid #555555;
}
.offering-title {
  align-items: center;
  justify-content: center;
  position: relative;
  top: 5px;
}
.all-offerings {
  background-color: #ffffff;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
}
.workshop-offering {
  background-color: #ffffff33;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
}

@media (max-width: 500px) {
  rtdewsa .ws-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .fixed-side {
    position: static;
    width: 500px;
  }
  .expertPage {
    width: 35%;
    height: 33vh;
  }
  .WorkShopCard {
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 50;
    background-color: black;
  }
  .feedbackcard {
    width: 100%;
  }
  .ws-container1 {
    width: 100%;
    height: 40%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: linear-gradient(360deg, #313131 0%, #000000 100%);
  }
  .ws-container2 {
    width: 100%;
    height: 70%;
    display: flex;
    flex-direction: row;
    background-color: yellow;
  }
  .ct-logo {
    display: none;
  }
  .expert-name {
    font-size: 30px;
  }
  .expert-skill {
    font-size: 12px;
  }
  .modal-overlay {
    display:none !important;
  }
  .ct-workshop-semi-16 {
    font-size: 30px !important;
    font-family: "Gilroy-SemiBold", sans-serif !important;
    font-weight: 600;
  }
}

@media (min-width: 500px) and (max-width: 991px) {
  .expertPage {
    width: 40%;
    height: 30vh;
  }

  .WorkShopCard {
    flex-direction: column;
  }
  .feedbackcard {
    width: 100%;
  }
  .ct-cirka-size-48 {
    font-size: 30px;
    font-family: "Cirka-Bold", sans-serif;
    font-weight: 700;
  }
  .ct-text-size-16-med {
    font-size: 14px;
    font-family: "Gilroy-medium", sans-serif;
    font-weight: 400;
  }
  .ct-text-size-med-24 {
    font-size: 22px;
    font-family: "Gilroy-medium", sans-serif;
    font-weight: 400;
  }
}
.expert-rating {
  background-color: #ffffff0f;
  width: 100px;
  border-radius: 12px;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.expert-skills {
  background-color: #ffffff0f;
  width: 130px;
  border-radius: 12px;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border: 1px solid #555555;
}
.card-body-1 {
  width: 100%;
  background-color: #151515;
  border-radius: 10px;
  gap: 10px;
  border: 1px solid #474747;
  margin-bottom: 20px;
  padding: 10px;

}
.ws-card {
  background-color: #ffe9a7;
  color: #814416;
  width: 100px;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.ws-rating {
  background-color: #ffffff0f;
  width: 70px;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.icon-tag {
  height: 60px;
  width: 50%;
  display: flex;
  color: #ffffff;
  background-color: #1c1c1e;
  border-radius: 10px;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
.calendar-icon {
  width: 45px;
  height: 45px;
  color: #ffffff;
  background-color: #393939;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
}
.inr-price {
  width: 100%;
  align-items: center;
  justify-content: space-between;
  height: 50px;
}
.register-now {
  width: 120px;
  height: 40px;
  border-radius: 8px;
  background-color: #FFFFFF;
  border: none;
}
.feedback-title{
  background-Color: #F3E2FB;
  color: #8231A7;
  width: 100px;
  border-Radius: 50px;
  align-Items: center;
  justify-Content: center;
  gap: 5px; 
}
.myswiper{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
.star-icon-position {
  position: relative;
  bottom: 5px;
}